<template>
 <form
  @submit.prevent="
   this.title === $t('callqueue.updateTimeSlot')
    ? updateTimeSlot(this.id, $t('timeSlotUpdatedSuccessfully'))
    : updateException(this.id, $t('exceptionUpdatedSuccessfully'))
  "
  class="space-y-8 divide-y divide-gray-200 form_padding z-10 border border-gray-200 rounded-md"
  method="POST">
  <div class="pt-4 space-y-6 sm:pt-4 sm:space-y-5">
   <div>
    <h3 class="text-lg leading-6 font-medium text-gray-900">
     {{ this.title }}
    </h3>
    <p class="mt-1 max-w-2xl text-sm text-gray-500">
     {{ $t("callqueue.subTitle") }}.
    </p>
   </div>
   <div class="space-y-6 sm:space-y-5">
    <div
     v-if="this.title === $t('callqueue.updateTimeSlot')"
     class="sm:grid sm:grid-cols-3 sm:gap-4 sm:items-start sm:border-t sm:border-gray-200 sm:pt-5">
     <label
      for="name"
      class="block text-sm font-medium text-gray-700 sm:mt-px sm:pt-2">
      {{ $t("callqueueTable.start") }}
     </label>
     <div class="mt-1 relative sm:mt-0 sm:col-span-2">
      <input
       v-model="this.form.start"
       @input="this.checkmakingTimeSlot()"
       type="time"
       required
       name="start-timeslot"
       id="start-timeslot"
       aria-invalid="true"
       :class="[
        this.checkmakingTimeSlot().checkStart ||
        this.checkmakingTimeSlot().checkBetween ||
        (this.pickedStartBigger && this.form.start && this.form.end)
         ? 'mt-1 ring-red-500 border-red-300 focus:ring-red-500 focus:border-red-500 block w-full shadow-sm sm:text-sm rounded'
         : 'mt-1 block w-full shadow-sm sm:text-sm border-gray-300 rounded',
       ]" />
      <div
       v-if="
        this.checkmakingTimeSlot().checkStart ||
        this.checkmakingTimeSlot().checkBetween ||
        (this.pickedStartBigger && this.form.start && this.form.end)
       "
       class="absolute inset-y-0 right-0 pr-3 flex items-center pointer-events-none">
       <ExclamationCircleIcon class="h-5 w-5 text-red-500" aria-hidden="true" />
      </div>
     </div>
     <p
      v-if="this.checkmakingTimeSlot().checkStart"
      class="col-start-2 -mt-3 ml-1 text-sm text-red-600"
      id="email-error">
      {{ $t("callqueueTable.checkStart") }}
     </p>
     <p
      v-else-if="this.checkmakingTimeSlot().checkBetween"
      class="col-start-2 -mt-3 ml-1 text-sm text-red-600"
      id="email-error">
      {{ $t("callqueueTable.checkBetween") }}
     </p>
     <p
      v-else-if="this.pickedStartBigger && this.form.start && this.form.end"
      class="col-start-2 -mt-3 ml-1 text-sm text-red-600"
      id="email-error">
      {{ $t("callqueueTable.pickedStartBigger") }}
     </p>
    </div>
    <div
     v-if="this.title === $t('callqueue.updateTimeSlot')"
     class="sm:grid sm:grid-cols-3 sm:gap-4 sm:items-start sm:border-t sm:border-gray-200 sm:pt-5">
     <label
      for="day"
      class="block text-sm font-medium text-gray-700 sm:mt-px sm:pt-2">
      {{ $t("callqueueTable.end") }}
     </label>
     <div class="mt-1 relative sm:mt-0 sm:col-span-2">
      <input
       v-model="this.form.end"
       @input="checkmakingTimeSlot()"
       type="time"
       required
       name="end-timeslot"
       id="end-timeslot"
       aria-invalid="true"
       :class="[
        this.checkmakingTimeSlot().checkEnd ||
        this.checkmakingTimeSlot().checkBetween ||
        (this.pickedStartBigger && this.form.start && this.form.end)
         ? 'mt-1 ring-red-500 border-red-300 focus:ring-red-500 focus:border-red-500 block w-full shadow-sm sm:text-sm rounded'
         : 'mt-1 focus:encom_border_input block w-full shadow-sm sm:text-sm border-gray-300 rounded',
       ]" />
      <div
       v-if="
        this.checkmakingTimeSlot().checkStart ||
        this.checkmakingTimeSlot().checkBetween ||
        (this.pickedStartBigger && this.form.start && this.form.end)
       "
       class="absolute inset-y-0 right-0 pr-3 flex items-center pointer-events-none">
       <ExclamationCircleIcon class="h-5 w-5 text-red-500" aria-hidden="true" />
      </div>
     </div>
     <p
      v-if="this.checkmakingTimeSlot().checkStart"
      class="col-start-2 -mt-3 ml-1 text-sm text-red-600"
      id="email-error">
      {{ $t("callqueueTable.checkStart") }}
     </p>
     <p
      v-else-if="this.checkmakingTimeSlot().checkBetween"
      class="col-start-2 -mt-3 ml-1 text-sm text-red-600"
      id="email-error">
      {{ $t("callqueueTable.checkBetween") }}
     </p>
     <p
      v-else-if="this.pickedStartBigger && this.form.start && this.form.end"
      class="col-start-2 -mt-3 ml-1 text-sm text-red-600"
      id="email-error">
      {{ $t("callqueueTable.pickedStartBigger") }}
     </p>
    </div>
    <div
     v-if="this.title === $t('callqueue.updateException')"
     class="sm:grid sm:grid-cols-3 sm:gap-4 sm:items-start sm:border-t sm:border-gray-200 sm:pt-5">
     <label
      for="callqueue-number"
      class="capitalize block text-sm font-bold text-gray-700 sm:mt-px sm:pt-2">
      {{ $t("callqueueTable.day") }}
     </label>
     <div class="mt-1 col-span-1 capitalize">
      <v-date-picker
       :min-date="new Date()"
       :locale="$i18n.locale"
       :attributes="attributes"
       v-model="this.form.day"
       @click="
        checkmakingOfBankHolidays(),
         checkmakingOfDate(),
         (this.closeNotification = true),
         (this.errorMessage = false)
       "
       title-position="left"
       trim-weeks
       is-expanded
       color="teal">
      </v-date-picker>
     </div>
     <div
      v-if="this.form.day || this.errorMessage"
      class="col-span-1 border rounded p-2">
      <h3 class="text-lg pb-2 font-semibold leading-6 text-gray-900">
       {{ $t("callqueueTable.dateSelected") }}
      </h3>
      <p class="block capitalize text-base pb-2 text-gray-700">
       {{ this.fullFormatDateFrench(this.form.day) }}
      </p>

      <transition :name="computedTransition">
       <div
        v-if="this.closeNotification"
        :class="[
         !checkmakingOfDate() && !this.errorMessage
          ? 'rounded bg-green-50 p-4'
          : 'rounded bg-red-50 p-4',
        ]">
        <div class="flex">
         <div class="flex-shrink-0">
          <CheckCircleIcon
           v-show="!checkmakingOfDate() && !this.errorMessage"
           class="h-5 w-5 text-green-400"
           aria-hidden="true" />
          <XCircleIcon
           v-show="checkmakingOfDate() || this.errorMessage"
           class="h-5 w-5 text-red-400"
           aria-hidden="true" />
         </div>
         <div class="ml-3">
          <p
           :class="[
            !checkmakingOfDate() && !this.errorMessage
             ? 'text-sm font-medium text-green-800'
             : 'text-sm font-bold text-red-800',
           ]">
           {{
            !checkmakingOfDate() && !this.errorMessage
             ? $t("callqueueTable.newExceptionAdded")
             : $t("callqueueTable.newExceptionError")
           }}
          </p>

          <p v-show="checkmakingOfDate()" class="text-sm text-red-700">
           {{ $t("callqueueTable.newExceptionExist") }}
          </p>
          <p v-show="this.errorMessage" class="text-sm text-red-700">
           {{ $t("callqueueTable.selectExceptionError") }}
          </p>
         </div>
         <div class="ml-auto pl-3">
          <div class="-mx-1.5 -my-1.5">
           <button
            type="button"
            @click="this.closeNotification = false"
            :class="[
             !checkmakingOfDate() && !this.errorMessage
              ? 'inline-flex bg-green-50 rounded p-1.5 text-green-500 hover:bg-green-100 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-offset-green-50 focus:ring-green-600'
              : 'inline-flex bg-red-50 rounded p-1.5 text-red-500 hover:bg-red-100 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-offset-red-50 focus:ring-red-600',
            ]">
            <span class="sr-only">Dismiss</span>
            <XIcon class="h-5 w-5" aria-hidden="true" />
           </button>
          </div>
         </div>
        </div>
       </div>
      </transition>
      <label
       for="reference"
       class="capitalize block text-sm font-normal pt-5 text-gray-700">
       {{ $t("callqueueTable.reference") }}
      </label>
      <input
       v-model="this.form.reference"
       required
       type="text"
       name="reference"
       id="reference"
       :placeholder="this.exceptionReference"
       class="mt-1 focus:encom_border_input block w-full shadow-sm sm:text-sm border-gray-300 rounded" />
     </div>
    </div>

    <div
     v-if="this.title === $t('callqueue.updateTimeSlot')"
     class="sm:grid sm:grid-cols-3 sm:gap-4 sm:items-start sm:border-t sm:border-gray-200 sm:pt-5">
     <label
      for="reference"
      class="capitalize block text-sm font-medium text-gray-700 sm:mt-px sm:pt-2">
      {{ $t("callqueueTable.reference") }}
     </label>
     <div class="mt-1 sm:mt-0 sm:col-span-2">
      <input
       v-model="this.form.reference"
       type="text"
       name="reference"
       id="reference"
       class="mt-1 focus:encom_border_input block w-full shadow-sm sm:text-sm border-gray-300 rounded"
       :placeholder="this.exceptionReference" />
     </div>
    </div>
    <div
     class="sm:grid sm:grid-cols-3 sm:gap-4 sm:items-start sm:border-t sm:border-gray-200 sm:pt-5">
     <label
      for="street-address"
      class="capitalize block text-sm font-medium text-gray-700 sm:mt-px sm:pt-2">
      Type de destination
     </label>
     <select
      v-model="destinationType"
      min="0"
      name="default_destination_type"
      id="default_destination_type"
      class="mt-1 focus:encom_border_input block w-full shadow-sm sm:text-sm border-gray-300 rounded">
      <option v-for="type in dest_type" :key="type.value" :value="type.value">
       {{ $t(type.name) }}
      </option>
     </select>
    </div>
    <div
     class="sm:grid sm:grid-cols-3 sm:gap-4 sm:items-start sm:border-t sm:border-gray-200 sm:pt-5">
     <label
      for="street-address"
      class="capitalize block text-sm font-medium text-gray-700 sm:mt-px sm:pt-2">
      Destination
     </label>
     <vue-select
      v-if="
       !this.form.destination_type ||
       this.form.destination_type === 'extension' ||
       this.form.destination_type === 'callQueue' ||
       this.form.destination_type === 'voicemail'
      "
      v-model="searchDestNumber"
      :options="selectOptions"
      label-by="label"
      :close-on-select="true"
      :disabled="selectOptions.length <= 0"
      searchable
      clear-on-select
      :multiple="false"
      :placeholder="searchDestNumber ? searchDestNumber : $t('search')"
      class="mt-1 focus:encom_border_input block w-full py-1 shadow-sm sm:text-sm border-gray-300 rounded">
     </vue-select>
     <div v-else-if="this.form.destination_type === 'externalNumber'">
      <input
       v-model="searchDestNumber"
       type="text"
       name="default_destination"
       id="default_destination"
       :class="
        ({ 'is-invalid': phoneError },
        'block w-full rounded-md border-0 py-1.5 text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-2 focus:ring-inset sm:text-sm sm:leading-6')
       " />
      <span v-if="phoneError" class="error">{{ phoneError }}</span>
     </div>
     <div v-else-if="this.form.destination_type === 'endCall'">
      <input
       v-model="searchDestNumber"
       type="text"
       name="default_destination"
       id="default_destination"
       disabled=""
       class="mt-1 focus:encom_border_input block w-full shadow-sm sm:text-sm border-gray-300 rounded bg-gray-200 cursor-not-allowed" />
     </div>
    </div>
   </div>
  </div>

  <div class="pt-5">
   <div class="flex justify-end">
    <a
     href="javascript:void(0)"
     v-on:click="$emit('closeForm')"
     class="rounded bg-white py-2 px-4 border border-gray-300 shadow-sm text-sm font-medium text-gray-700 hover:bg-gray-50">
     {{ $t("cancel") }}
    </a>
    <button
     type="submit"
     class="capitalize rounded ml-3 inline-flex justify-center py-2 px-4 border border-transparent shadow-sm text-sm font-medium body__table--header text-white">
     {{ $t("validate") }}
    </button>
   </div>
  </div>
 </form>

 <ComfirmationDialog
  v-if="this.showMessage"
  :messageToShow="this.messageToShow"
  :subMessageToShow="this.subMessageToShow" />
</template>

<script>
import axios from "axios";
import ComfirmationDialog from "./ConfirmationDialog.vue";
import {
 ExclamationIcon,
 ExclamationCircleIcon,
 CheckCircleIcon,
 SelectorIcon,
 XCircleIcon,
 XIcon,
} from "@heroicons/vue/solid";
const account = localStorage.getItem("account");

const dest_type = [
 { name: "extension", value: "extension" },
 { name: "voicemail", value: "voicemail" },
 //  { name: "svi", value: "svi" },
 { name: "queue", value: "callQueue" },
 //  { name: "call flow", value: "callFlow" },
 { name: "end call", value: "endCall" },
 { name: "external number", value: "externalNumber" },
];

export default {
 props: [
  "id",
  "callQueueGroupTimeSlot",
  "callQueueGroupTimeSpansId",
  "callQueueGroupExceptionId",
  "call_queue_time_spans",
  "call_queue_group_time_spans",
  "dayOfWeek",
  "fullDate",
  "call_queue_exceptions",
  "call_queue_group_exceptions",
  "title",
  "exceptionReference",
  "exceptionDestination",
  "formatDateFrench",
  "fullFormatDateFrench",
 ],
 components: {
  ComfirmationDialog,
  ExclamationIcon,
  ExclamationCircleIcon,
  CheckCircleIcon,
  SelectorIcon,
  XCircleIcon,
  XIcon,
 },
 data() {
  return {
   dest_type,
   destinationType: "",
   selectOptions: [],
   selectOptionsNumbers: [],
   phoneError: null,
   searchDestNumber: "",
   destNumber: null,
   searchNumber: "",
   frenchHolidaysDateOver12Months: [],
   form: {
    destination_type: null,
    number: "",
    day: "",
    name: "",
    code: "",
    fullDate: null,
    dayOfWeek: null,
    start: "",
    end: "",
    reference: "",
    destination: "",
   },
   account,
   errorMessage: false,
   closeNotification: true,
   withTransition: true,
   showMessage: false,
   messageToShow: "",
   subMessageToShow: "",
   callQueuesOptions: null,
   extensionOptions: [],
   queueOptions: [],
   didOptions: [],
  };
 },
 methods: {
  async getCallQueuesOptions() {
   const res = await axios.get(
    `${this.$cookie.getCookie(
     "API"
    )}/api/v1/callQueues/options?customerAccount=${this.account}`,
    {
     headers: {
      Authorization: `Bearer ${localStorage.getItem("token")}`,
     },
    }
   );

   try {
    this.callQueuesOptions = res.data;
    console.log("getCallQueuesOptions ~ res.data:", res.data);
    this.fillDest(res.data);
    // this.callQueuesDIDOptions = res.data?.dids;
    // this.callQueuesExtensionOptions = res.data?.extensions;
    // this.callQueuesQueueOptions = res.data?.queues;
   } catch (error) {
    this.errorHandling(error);
   }
  },
  fillDest(data) {
   Object.keys(data?.extensions).forEach(val => {
    let obj = {};
    obj["value"] = val;
    obj["label"] = data?.extensions[val];
    this.extensionOptions.push(obj);
   });
   Object.keys(data?.queues).forEach(val => {
    let objQueue = {};
    objQueue["value"] = val;
    objQueue["label"] = data?.queues[val];
    this.queueOptions.push(objQueue);
   });
  },
  fillOptions() {
   let queues = [];
   let dids = [];

   Object.keys(this.callQueuesOptions?.queues).forEach(val => {
    let obj = {};
    obj["value"] = val;
    obj["label"] = this.callQueuesOptions?.queues[val];
    queues.push(obj);
   });
   Object.keys(this.callQueuesOptions?.dids).forEach(val => {
    let obj = {};
    obj["value"] = val;
    obj["label"] = this.callQueuesOptions?.dids[val];
    dids.push(obj);
   });
   switch (this.type) {
    case "did":
     this.selectOptionsNumbers = dids;
     break;
    case "queue":
     this.selectOptionsNumbers = queues;
     break;

    default:
     console.log("Unknown action");
     break;
   }
  },
  async getFrenchHolidaysOver12Months() {
   const res = await axios.get(
    `${this.$cookie.getCookie(
     "API"
    )}/api/v1/helpers/time/frenchHolidaysOver12Months`,

    {
     headers: {
      "Content-Type": "application/json",
     },
    }
   );

   try {
    this.frenchHolidaysOver12Months = res.data.response;
    for (const key in this.frenchHolidaysOver12Months) {
     if (Object.hasOwnProperty.call(this.frenchHolidaysOver12Months, key)) {
      this.frenchHolidaysDateOver12Months.push(
       this.frenchHolidaysOver12Months[key]
      );
     }
    }
   } catch (error) {
    this.errorHandling(error);
   }
  },
  updateTimeSlot(id, msg) {
   if (this.callQueueGroupTimeSpansId) {
    const options = {
     method: "PUT",
     url: `${this.$cookie.getCookie("API")}/api/v1/callQueueGroupTimeSpans/${
      this.callQueueGroupTimeSpansId
     }`,
     params: {
      customerAccount: this.account,
     },
     headers: {
      "Content-Type": "application/json",
      Authorization: `Bearer ${localStorage.getItem("token")}`,
     },
     data: {
      start: this.form.start,
      end: this.form.end,
      reference: this.form.reference,
     },
    };

    axios
     .request(options)
     .then(res => {
      this.$emit("activeBanner");
      this.$emit("success", true);
      this.$emit("successMsg", msg);
     })
     .catch(error => {
      this.errorHandling(error);
     });
   } else {
    const options = {
     method: "PUT",
     url: `${this.$cookie.getCookie("API")}/api/v1/callQueueTimeSpans/${id}`,
     params: {
      customerAccount: this.account,
     },
     headers: {
      "Content-Type": "application/json",
      Authorization: `Bearer ${localStorage.getItem("token")}`,
     },
     data: {
      start: this.form.start,
      end: this.form.end,
      reference: this.form.reference,
      destination: this.form.destination,
      destination_type: this.form.destination_type,
     },
    };

    axios
     .request(options)
     .then(res => {
      this.$emit("activeBanner");
      this.$emit("success", true);
      this.$emit("successMsg", msg);
     })
     .catch(error => {
      this.errorHandling(error);
     });
   }
  },
  updateException(id, msg) {
   if (this.callQueueGroupExceptionId) {
    const options = {
     method: "PUT",
     url: `${this.$cookie.getCookie("API")}/api/v1/callQueueGroupExceptions/${
      this.callQueueGroupExceptionId
     }`,
     params: {
      customerAccount: this.account,
     },
     headers: {
      "Content-Type": "application/json",
      Authorization: `Bearer ${localStorage.getItem("token")}`,
     },
     data: { day: this.form.day, reference: this.form.reference },
    };

    if (this.form.day && !this.checkmakingOfDate()) {
     axios
      .request(options)
      .catch(err => {
       if (err.response) {
        if (err.response.status == 500) {
         window.alert(
          "Oups, something went wrong...\nReason: " + err.response.statusText
         );
        } else if (err.response.status == 404) {
         window.alert("Document " + err.response.statusText);
        }
       }
      })
      .then(res => {
       this.$emit("activeBanner");
       this.$emit("success", true);
       this.$emit("successMsg", msg);
      });
    } else if (!this.form.day) {
     this.errorMessage = true;
    } else {
    }
   } else {
    const options = {
     method: "PUT",
     url: `${this.$cookie.getCookie("API")}/api/v1/callQueueExceptions/${id}`,
     params: {
      customerAccount: this.account,
     },
     headers: {
      "Content-Type": "application/json",
      Authorization: `Bearer ${localStorage.getItem("token")}`,
     },
     data: {
      day: this.form.day,
      reference: this.form.reference,
      destination: this.form.destination,
      destination_type: this.form.destination_type,
     },
    };
    if (this.form.day && !this.checkmakingOfDate()) {
     axios
      .request(options)
      .catch(err => {
       if (err.response) {
        if (err.response.status == 500) {
         window.alert(
          "Oups, something went wrong...\nReason: " + err.response.statusText
         );
        } else if (err.response.status == 404) {
         window.alert("Document " + err.response.statusText);
        }
       }
      })
      .then(res => {
       this.$emit("activeBanner");
       this.$emit("success", true);
       this.$emit("successMsg", msg);
      });
    } else if (!this.form.day) {
     this.errorMessage = true;
    } else {
    }
   }
  },
  checkmakingOfDate() {
   if (this.call_queue_group_exceptions) {
    let pickedDay =
     JSON.stringify(this.form.day).slice(1, 11) + "T00:00:00.000000Z";
    let day = this.call_queue_group_exceptions.find(
     day => day.day === pickedDay
    );
    if (day) {
     return true;
    } else {
     return false;
    }
   } else {
    let pickedDay =
     JSON.stringify(this.form.day).slice(1, 11) + "T00:00:00.000000Z";
    let day = this.call_queue_exceptions.find(day => day.day === pickedDay);
    if (day) {
     return true;
    } else {
     return false;
    }
   }
  },
  checkmakingOfBankHolidays() {
   this.form.reference = null;
   let pickedDay =
    JSON.stringify(this.form.day).slice(1, 11) + "T00:00:00.000000Z";
   let bankHolyday = this.frenchHolidaysDateOver12Months.find(
    bankHolyday =>
     this.formatDateFrench(bankHolyday) === this.formatDateFrench(pickedDay)
   );
   for (const [frenchHolidays, date] of Object.entries(
    this.frenchHolidaysOver12Months
   )) {
    if (date === bankHolyday) {
     this.form.reference = frenchHolidays;
    }
   }
  },
  checkmakingTimeSlot() {
   let pickedStart = this.form.start;
   let pickedStartSplit = pickedStart.split(":");
   let pickedStartInt = parseInt(pickedStartSplit[0] + pickedStartSplit[1]);
   let pickedEnd = this.form.end;
   let pickedEndSplit = pickedEnd.split(":");
   let pickedEndInt = parseInt(pickedEndSplit[0] + pickedEndSplit[1]);
   let pickedDay = [];
   let checkDay = [];
   let timeSlotToEdit = [];
   let checkStart;
   let checkEnd;
   let checkBetween;
   this.pickedStartBigger = false;
   if (this.dayOfWeek) {
    pickedDay.push(this.dayOfWeek);
   } else {
    pickedDay.push(this.fullDate);
   }

   //Check if i'm inside a callqueue group detail
   if (this.call_queue_group_time_spans) {
    for (const value of pickedDay) {
     if (typeof value == "number") {
      const res = this.call_queue_group_time_spans.filter(
       call_queue_group => call_queue_group.dayOfWeek === value
      );
      if (res.length > 0 && pickedStartInt < pickedEndInt) {
       for (let index = 0; index < res.length; index++) {
        const timeSpans = res[index];
        checkDay.push(timeSpans);
        timeSlotToEdit = checkDay.filter(day => day.id != this.id);
        checkStart = timeSlotToEdit.find(
         checkStart =>
          //Issue on only picked start
          //picked start bigger than existed start and smaller than existed end
          pickedStart >= checkStart.start.slice(0, 5) &&
          pickedStart <= checkStart.end.slice(0, 5)
        );
        checkEnd = timeSlotToEdit.find(
         checkEnd =>
          //Issue on only picked end
          //picked end bigger than existed start and smaller than existed end
          pickedEnd >= checkEnd.start.slice(0, 5) &&
          pickedEnd <= checkEnd.end.slice(0, 5)
        );
        checkBetween = timeSlotToEdit.find(
         checkBetween =>
          //Issue on picked start and picked end
          //picked start smaller than existed start and picked end smaller than existed end
          checkBetween.end.slice(0, 5) <= pickedEnd &&
          pickedStart <= checkBetween.start.slice(0, 5)
        );
       }
      } else if (pickedStartInt >= pickedEndInt) {
       //picked start bigger than picked end
       this.pickedStartBigger = true;
      }
     } else {
      const res = this.call_queue_group_time_spans.filter(
       call_queue_group => call_queue_group.fullDate === value
      );

      if (res.length > 0 && pickedStartInt < pickedEndInt) {
       for (let index = 0; index < res.length; index++) {
        const timeSpans = res[index];
        checkDay.push(timeSpans);
        timeSlotToEdit = checkDay.filter(day => day.id != this.id);
        checkStart = timeSlotToEdit.find(
         checkStart =>
          //Issue on only picked start
          //picked start bigger than existed start and smaller than existed end
          pickedStart >= checkStart.start.slice(0, 5) &&
          pickedStart <= checkStart.end.slice(0, 5)
        );
        checkEnd = timeSlotToEdit.find(
         checkEnd =>
          //Issue on only picked end
          //picked end bigger than existed start and smaller than existed end
          pickedEnd >= checkEnd.start.slice(0, 5) &&
          pickedEnd <= checkEnd.end.slice(0, 5)
        );
        checkBetween = timeSlotToEdit.find(
         checkBetween =>
          //Issue on picked start and picked end
          //picked start smaller than existed start and picked end smaller than existed end
          checkBetween.end.slice(0, 5) <= pickedEnd &&
          pickedStart <= checkBetween.start.slice(0, 5)
        );
       }
      } else if (pickedStartInt >= pickedEndInt) {
       //picked start bigger than picked end
       this.pickedStartBigger = true;
      }
     }
    }
   } else {
    //inside a callqueue detail
    for (const value of pickedDay) {
     if (typeof value == "number") {
      const res = this.call_queue_time_spans.filter(
       call_queue_time_spans => call_queue_time_spans.dayOfWeek === value
      );
      if (res.length > 0 && pickedStartInt < pickedEndInt) {
       for (let index = 0; index < res.length; index++) {
        const timeSpans = res[index];
        checkDay.push(timeSpans);
        timeSlotToEdit = checkDay.filter(day => day.id != this.id);
        checkStart = timeSlotToEdit.find(
         checkStart =>
          //Issue on only picked start
          //picked start bigger than existed start and smaller than existed end
          pickedStart >= checkStart.start.slice(0, 5) &&
          pickedStart <= checkStart.end.slice(0, 5)
        );
        checkEnd = timeSlotToEdit.find(
         checkEnd =>
          //Issue on only picked end
          //picked end bigger than existed start and smaller than existed end
          pickedEnd >= checkEnd.start.slice(0, 5) &&
          pickedEnd <= checkEnd.end.slice(0, 5)
        );
        checkBetween = timeSlotToEdit.find(
         checkBetween =>
          //Issue on picked start and picked end
          //picked start smaller than existed start and picked end smaller than existed end
          checkBetween.end.slice(0, 5) <= pickedEnd &&
          pickedStart <= checkBetween.start.slice(0, 5)
        );
       }
      } else if (pickedStartInt >= pickedEndInt) {
       this.pickedStartBigger = true;
      }
     } else {
      const res = this.call_queue_time_spans.filter(
       call_queue_time_spans => call_queue_time_spans.fullDate === value
      );
      if (res.length > 0 && pickedStartInt < pickedEndInt) {
       for (let index = 0; index < res.length; index++) {
        const timeSpans = res[index];
        checkDay.push(timeSpans);
        timeSlotToEdit = checkDay.filter(day => day.id != this.id);
        checkStart = timeSlotToEdit.find(
         checkStart =>
          //Issue on only picked start
          //picked start bigger than existed start and smaller than existed end
          pickedStart >= checkStart.start.slice(0, 5) &&
          pickedStart <= checkStart.end.slice(0, 5)
        );
        checkEnd = timeSlotToEdit.find(
         checkEnd =>
          //Issue on only picked end
          //picked end bigger than existed start and smaller than existed end
          pickedEnd >= checkEnd.start.slice(0, 5) &&
          pickedEnd <= checkEnd.end.slice(0, 5)
        );
        checkBetween = timeSlotToEdit.find(
         checkBetween =>
          //Issue on picked start and picked end
          //picked start smaller than existed start and picked end smaller than existed end
          checkBetween.end.slice(0, 5) <= pickedEnd &&
          pickedStart <= checkBetween.start.slice(0, 5)
        );
       }
      } else if (pickedStartInt >= pickedEndInt) {
       this.pickedStartBigger = true;
      }
     }
    }
   }

   return { checkStart, checkEnd, checkBetween };
  },
  sendMessage(msg, subMsg) {
   this.showMessage = true;
   this.messageToShow = msg;
   this.subMessageToShow = subMsg;
  },
 },
 watch: {
  searchDestNumber: function (val) {
   if (this.destinationType === "externalNumber") {
    this.validatePhoneNumber();
    this.destNumber = val;
    this.form.destination = val;
   } else {
    this.destNumber = val?.value;
    this.form.destination = val?.value;
   }
  },
  destinationType: function (val) {
   this.form.destination_type = val;
   switch (val) {
    case "extension":
     this.selectOptions = this.extensionOptions;
     break;
    case "voicemail":
     this.selectOptions = this.extensionOptions;
     break;
    case "svi":
     // this.selectOptions = extensions;
     break;
    case "callQueue":
     this.selectOptions = this.queueOptions;
     break;
    case "callFlow":
     // this.selectOptions = extensions;
     break;
    case "endCall":
     // this.selectOptions = extensions;
     break;
    case "externalNumber":
     this.searchDestNumber = "";
     // this.selectOptions = extensions;
     break;
    default:
     console.log("Unknown action");
     break;
   }
  },
 },
 mounted() {
  this.getFrenchHolidaysOver12Months();
  this.getCallQueuesOptions();
  if (this.callQueueGroupTimeSlot) {
   //  console.log("mounted ~ this.callQueueGroupTimeSlot:", this.callQueueGroupTimeSlot)
   this.searchDestNumber = this.callQueueGroupTimeSlot.destination;
   this.destinationType = this.callQueueGroupTimeSlot.destination_type;
   this.form.dayOfWeek = this.callQueueGroupTimeSlot.dayOfWeek;
   this.form.start = this.callQueueGroupTimeSlot.start.substring(0, 5);
   this.form.end = this.callQueueGroupTimeSlot.end.substring(0, 5);
   this.form.reference = this.callQueueGroupTimeSlot.reference;
   this.form.fullDate = this.callQueueGroupTimeSlot.fullDate;
   this.form.destination = this.callQueueGroupTimeSlot.destination;
   this.form.destination_type = this.callQueueGroupTimeSlot.destination_type;
   this.destNumber = this.callQueueGroupTimeSlot.destination;
   this.destinationType = this.callQueueGroupTimeSlot.destination_type;
   this.searchDestNumber = this.callQueueGroupTimeSlot.destination;
  }
 },
 computed: {
  computedTransition() {
   return this.withTransition && "fade";
  },
 },
};
</script>

<style scoped>
.is-invalid {
 border: 1px solid red !important;
 /* border-color: red !important; */
}
.error {
 color: red;
 font-size: 0.775em;
}
</style>
