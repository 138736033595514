<template>
 <div
  v-if="
   this.openTimeSlotForm || this.openExceptionForm || this.openBankHolidaysForm
  ">
  <div class="bg-white px-4 py-5 sm:p-6">
   <div class="border-b border-gray-200 mb-5">
    <div class="sm:flex sm:items-baseline">
     <h3 class="text-lg capitalize leading-6 font-medium text-gray-900">
      {{
       this.openTimeSlotForm
        ? $t("callqueueTable.timeslot")
        : $t("callqueueTable.exception")
      }}
     </h3>
     <div v-if="this.openTimeSlotForm" class="mt-4 sm:mt-0 sm:ml-10">
      <nav class="-mb-px flex space-x-8">
       <a
        @click="this.switchTabs()"
        :class="[
         this.weekDay
          ? 'encom_primary_thin_border encom_ouvert_ticket_text'
          : 'border-transparent text-gray-500 hover:text-gray-700 hover:border-gray-300',
         'whitespace-nowrap pb-4 px-1 border-b-2 font-medium text-sm cursor-pointer',
        ]">
        {{ $t("callqueueTable.weekday") }}
       </a>
       <a
        @click="this.switchTabs2()"
        :class="[
         this.specificDay
          ? 'encom_primary_thin_border encom_ouvert_ticket_text'
          : 'border-transparent text-gray-500 hover:text-gray-700 hover:border-gray-300',
         'whitespace-nowrap pb-4 px-1 border-b-2 font-medium text-sm cursor-pointer',
        ]">
        {{ $t("callqueueTable.specificDate") }}
       </a>
      </nav>
     </div>
    </div>
   </div>
   <div class="md:grid md:grid-cols-3 md:gap-6">
    <div class="md:col-span-1">
     <p class="mt-1 text-sm text-gray-500">
      {{
       this.openTimeSlotForm
        ? $t("callqueueTable.formTimeSlotDescription") + "."
        : this.openExceptionForm
        ? $t("callqueueTable.formExceptionDescription") + "."
        : $t("callqueueTable.formExceptionTwelveMonthsDescription") + "."
      }}
     </p>
    </div>
    <div class="mt-5 md:mt-0 md:col-span-2">
     <form
      @submit.prevent="
       this.openTimeSlotForm
        ? createTimeSlot(
           $t('timeSlotAddedSuccessfully'),
           $t('unlinkCallQueue'),
           $t('unlinkCallQueueTitle', { type: $t('timeSlot') }),
           $t('createAndUnlink'),
           $t('cancel')
          )
        : this.openExceptionForm
        ? createException(
           $t('exceptionAddedSuccessfully'),
           $t('unlinkCallQueue'),
           $t('unlinkCallQueueTitle', { type: $t('exception') }),
           $t('createAndUnlink'),
           $t('cancel')
          )
        : createExceptionForBankHolidays(
           $t('exceptionAddedSuccessfully'),
           $t('unlinkCallQueue'),
           $t('unlinkCallQueueTitle', { type: $t('exception') }),
           $t('createAndUnlink'),
           $t('cancel')
          )
      "
      method="POST">
      <div class="grid grid-cols-6 gap-6">
       <div v-if="this.openTimeSlotForm && this.weekDay" class="col-span-3">
        <div class="flex inline">
         <label for="weekdays" class="block text-sm font-bold text-gray-700">{{
          $t("callqueueTable.weekday")
         }}</label>
         <fieldset class="ml-5">
          <legend class="sr-only">Notifications</legend>
          <div class="relative flex items-start">
           <div class="flex items-center h-6">
            <input
             v-model="this.checked"
             :value="checked"
             id="comments"
             aria-describedby="comments-description"
             name="comments"
             type="checkbox"
             class="rounded focus:encom_border_input h-4 w-4 encom_ouvert_ticket_text border-gray-300" />
           </div>
           <div class="ml-2 text-sm">
            <label for="comments" class="font-small text-gray-700">{{
             $t("callqueueTable.selectAllDays")
            }}</label>
           </div>
          </div>
         </fieldset>
        </div>
        <Menu>
         <MenuButton
          class="mt-1 relative text-left pl-3 p-1.5 w-full border border-gray-300 bg-white rounded focus:outline-none focus:encom_border_input sm:text-sm cursor-default"
          ><span class="block truncate"
           >{{ $t("callqueueTable.inputSelectDays") }}

           <kbd
            class="ml-1 inline-flex items-center border border-gray-200 rounded px-2 text-sm font-sans font-medium text-gray-400">
            <svg
             xmlns="http://www.w3.org/2000/svg"
             class="h-3 w-3"
             fill="none"
             viewBox="0 0 24 24"
             stroke="currentColor">
             <path
              stroke-linecap="round"
              stroke-linejoin="round"
              stroke-width="3"
              d="M8 7l4-4m0 0l4 4m-4-4v18" />
            </svg>
            Shift
           </kbd>
           {{ $t("callqueueTable.or") }}
           <kbd
            class="ml-1 inline-flex items-center border border-gray-200 rounded px-2 text-sm font-sans font-medium text-gray-400">
            Ctrl
           </kbd>
          </span>
          <span
           class="absolute inset-y-0 right-0 flex items-center pr-2 pointer-events-none">
           <SelectorIcon
            class="h-5 w-5 text-gray-400"
            aria-hidden="true" /> </span
         ></MenuButton>
         <transition
          enter-active-class="transition ease-out duration-100"
          enter-from-class="transform opacity-0 scale-95"
          enter-to-class="transform opacity-100 scale-100"
          leave-active-class="transition ease-in duration-75"
          leave-from-class="transform opacity-100 scale-100"
          leave-to-class="transform opacity-0 scale-95">
          <MenuItems>
           <select
            v-model="this.form.dayOfWeek"
            id="weekdays"
            name="weekdays"
            required
            multiple
            class="capitalize mt-1 block w-full py-2 px-3 border border-gray-300 bg-white sm:text-sm">
            <option
             v-for="dayOfWeek in weekDays"
             :key="dayOfWeek.id"
             :value="dayOfWeek.id">
             {{ $t(dayOfWeek.day) }}
            </option>
           </select>
          </MenuItems>
         </transition>
        </Menu>
       </div>
       <div
        v-else-if="this.openTimeSlotForm && this.specificDay"
        class="col-span-6 grid grid-cols-10">
        <div class="col-span-5 mr-7 capitalize">
         <v-date-picker
          :key="dayClicked"
          :min-date="new Date()"
          :attributes="attributes"
          :locale="$i18n.locale"
          @dayclick="onDayClick"
          title-position="left"
          trim-weeks
          is-expanded
          color="teal">
         </v-date-picker>
        </div>

        <div class="col-span-5 col-start-6 border rounded p-2">
         <h3 class="text-sm font-bold text-gray-700 pb-2">
          {{ $t("callqueueTable.dateSelected") }}
         </h3>
         <div class="grid grid-cols-5">
          <p
           v-for="date in this.form.fullDates"
           :key="date"
           class="block text-sm text-gray-700">
           {{ date }}
          </p>
         </div>
         <label
          for="reference"
          class="block text-sm font-normal pt-5 text-gray-700 capitalize"
          >{{ $t("callqueueTable.reference") }}</label
         >
         <input
          v-model="this.form.reference"
          required
          type="text"
          name="reference"
          id="reference"
          autocomplete="reference"
          class="mt-1 focus:encom_border_input block w-full sm:text-sm border-gray-300 rounded" />
        </div>
       </div>
       <div
        v-if="this.openTimeSlotForm && this.weekDay"
        class="col-span-3 border rounded p-2">
        <label
         for="weekdays"
         class="block text-sm font-bold text-gray-700 pb-2"
         >{{ $t("callqueueTable.daySelected") }}</label
        >
        <p
         class="block capitalize text-sm pb-1 text-gray-700"
         v-if="this.form.dayOfWeek.length === 0 && !checked">
         None
        </p>
        <div class="grid grid-rows-3 grid-flow-col" v-if="!checked">
         <p
          v-for="id in this.form.dayOfWeek"
          :key="id"
          class="block capitalize text-sm pb-1 text-gray-700">
          {{ $t(this.weekDays[id - 1].day) }}
         </p>
        </div>
        <div class="grid grid-rows-3 grid-flow-col" v-if="checked">
         <p
          v-for="dayOfWeek in weekDays"
          :key="dayOfWeek.id"
          class="block capitalize text-sm pb-1 text-gray-700">
          {{ $t(dayOfWeek.day) }}
         </p>
        </div>
       </div>
       <div v-if="this.openTimeSlotForm" class="col-span-3 sm:col-span-3">
        <label
         for="start-timeslot"
         class="capitalize block text-sm font-bold text-gray-700">
         {{ $t("callqueueTable.start") }}
        </label>
        <div class="mt-1 relative rounded shadow-sm">
         <input
          v-model="this.form.start"
          @input="this.checkmakingTimeSlot()"
          type="time"
          required
          name="start-timeslot"
          id="start-timeslot"
          aria-invalid="true"
          :class="[
           this.checkmakingTimeSlot().checkStart ||
           this.checkmakingTimeSlot().checkBetween ||
           (this.pickedStartBigger && this.form.start && this.form.end)
            ? 'mt-1 ring-red-500 border-red-300 focus:ring-red-500 focus:border-red-500 block w-full shadow-sm sm:text-sm'
            : 'mt-1 block w-full shadow-sm sm:text-sm border-gray-300',
           'rounded',
          ]" />
         <div
          v-if="
           this.checkmakingTimeSlot().checkStart ||
           this.checkmakingTimeSlot().checkBetween ||
           (this.pickedStartBigger && this.form.start && this.form.end)
          "
          class="absolute inset-y-0 right-0 pr-3 flex items-center pointer-events-none">
          <ExclamationCircleIcon
           class="h-5 w-5 text-red-500"
           aria-hidden="true" />
         </div>
        </div>
        <p
         v-if="this.checkmakingTimeSlot().checkStart"
         class="mt-1 ml-1 text-sm text-red-600"
         id="email-error">
         {{ $t("callqueueTable.checkStart") }}
        </p>
        <p
         v-else-if="this.checkmakingTimeSlot().checkBetween"
         class="mt-1 ml-1 text-sm text-red-600"
         id="email-error">
         {{ $t("callqueueTable.checkBetween") }}
        </p>
        <p
         v-else-if="this.pickedStartBigger && this.form.start && this.form.end"
         class="mt-1 ml-1 text-sm text-red-600"
         id="email-error">
         {{ $t("callqueueTable.pickedStartBigger") }}
        </p>
       </div>
       <div v-if="this.openTimeSlotForm" class="col-span-3 sm:col-span-3">
        <label
         for="end-timeslot"
         class="capitalize block text-sm font-bold text-gray-700"
         >{{ $t("callqueueTable.end") }}</label
        >
        <div class="mt-1 relative rounded shadow-sm">
         <input
          v-model="this.form.end"
          @input="checkmakingTimeSlot()"
          type="time"
          required
          name="start-timeslot"
          id="start-timeslot"
          aria-invalid="true"
          :class="[
           this.checkmakingTimeSlot().checkEnd ||
           this.checkmakingTimeSlot().checkBetween ||
           (this.pickedStartBigger && this.form.start && this.form.end)
            ? 'mt-1 ring-red-500 border-red-300 focus:ring-red-500 focus:border-red-500 block w-full shadow-sm sm:text-sm rounded'
            : 'mt-1 focus:encom_border_input block w-full shadow-sm sm:text-sm border-gray-300 rounded',
          ]" />

         <div
          v-if="
           this.checkmakingTimeSlot().checkEnd ||
           this.checkmakingTimeSlot().checkBetween ||
           (this.pickedStartBigger && this.form.start && this.form.end)
          "
          class="absolute inset-y-0 right-0 pr-3 flex items-center pointer-events-none">
          <ExclamationCircleIcon
           class="h-5 w-5 text-red-500"
           aria-hidden="true" />
         </div>
        </div>
        <p
         v-if="this.checkmakingTimeSlot().checkEnd"
         class="mt-1 ml-1 text-sm text-red-600"
         id="email-error">
         {{ $t("callqueueTable.checkEnd") }}
        </p>
       </div>

       <div v-if="this.openTimeSlotForm && this.weekDay" class="col-span-6">
        <label
         for="reference"
         class="capitalize block text-sm font-normal text-gray-700"
         >{{ $t("callqueueTable.reference") }}</label
        >
        <input
         v-model="this.form.reference"
         type="text"
         name="reference"
         id="reference"
         autocomplete="reference"
         class="mt-1 focus:encom_border_input block w-full shadow-sm sm:text-sm border-gray-300 rounded" />
       </div>

       <div v-if="this.openExceptionForm" class="col-span-3 capitalize">
        <v-date-picker
         :min-date="new Date()"
         :locale="$i18n.locale"
         v-model="this.form.day"
         @click="
          checkmakingOfBankHolidays(),
           checkmakingOfDate(),
           (this.closeNotification = true),
           (this.errorMessage = false)
         "
         title-position="left"
         trim-weeks
         is-expanded
         color="teal">
        </v-date-picker>
       </div>
       <div
        v-if="
         (this.openExceptionForm && this.form.day) ||
         (this.openExceptionForm && this.errorMessage)
        "
        class="col-span-3 border rounded p-2">
        <h3 class="text-lg pb-2 font-semibold leading-6 text-gray-900">
         {{ $t("callqueueTable.dateSelected") }}
        </h3>
        <p class="block capitalize text-base pb-2 text-gray-700">
         {{ this.fullFormatDateFrench(this.form.day) }}
        </p>

        <transition :name="computedTransition">
         <div
          v-if="this.closeNotification"
          :class="[
           !checkmakingOfDate() && !this.errorMessage
            ? 'rounded bg-green-50 p-4'
            : 'rounded bg-red-50 p-4',
          ]">
          <div class="flex">
           <div class="flex-shrink-0">
            <CheckCircleIcon
             v-show="!checkmakingOfDate() && !this.errorMessage"
             class="h-5 w-5 text-green-400"
             aria-hidden="true" />
            <XCircleIcon
             v-show="checkmakingOfDate() || this.errorMessage"
             class="h-5 w-5 text-red-400"
             aria-hidden="true" />
           </div>
           <div class="ml-3">
            <p
             :class="[
              !checkmakingOfDate() && !this.errorMessage
               ? 'text-sm font-medium text-green-800'
               : 'text-sm font-bold text-red-800',
             ]">
             {{
              !checkmakingOfDate() && !this.errorMessage
               ? $t("callqueueTable.newExceptionAdded")
               : $t("callqueueTable.newExceptionError")
             }}
            </p>

            <p v-show="checkmakingOfDate()" class="text-sm text-red-700">
             {{ $t("callqueueTable.newExceptionExist") }}.
            </p>
            <p v-show="this.errorMessage" class="text-sm text-red-700">
             {{ $t("callqueueTable.selectExceptionError") }}.
            </p>
           </div>
           <div class="ml-auto pl-3">
            <div class="-mx-1.5 -my-1.5">
             <button
              type="button"
              @click="this.closeNotification = false"
              :class="[
               !checkmakingOfDate() && !this.errorMessage
                ? 'rounded inline-flex bg-green-50 rounded p-1.5 text-green-500 hover:bg-green-100 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-offset-green-50 focus:ring-green-600'
                : 'rounded inline-flex bg-red-50 rounded p-1.5 text-red-500 hover:bg-red-100 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-offset-red-50 focus:ring-red-600',
              ]">
              <span class="sr-only">Dismiss</span>
              <XIcon class="h-5 w-5" aria-hidden="true" />
             </button>
            </div>
           </div>
          </div>
         </div>
        </transition>
        <label
         for="reference"
         class="capitalize block text-sm font-normal pt-5 text-gray-700"
         >{{ $t("callqueueTable.reference") }}</label
        >
        <input
         v-model="this.form.reference"
         required
         type="text"
         name="reference"
         id="reference"
         autocomplete="reference"
         class="mt-1 focus:encom_border_input block w-full shadow-sm sm:text-sm border-gray-300 rounded" />
       </div>
       <div v-if="!this.openBankHolidaysForm" class="col-span-6">
        <label
         for="street-address"
         class="capitalize block text-sm font-normal text-gray-700">
         Type de destination
        </label>
        <select
         v-model="destinationType"
         min="0"
         name="default_destination_type"
         id="default_destination_type"
         class="mt-1 focus:encom_border_input block w-full shadow-sm sm:text-sm border-gray-300 rounded">
         <option
          v-for="type in dest_type"
          :key="type.value"
          :value="type.value">
          {{ $t(type.name) }}
         </option>
        </select>
        <!-- <input
         v-model="this.form.destination"
         type="tel"
         name="destination"
         id="destination"
         autocomplete="destination"
         class="mt-1 focus:encom_border_input block w-full shadow-sm sm:text-sm border-gray-300 rounded"
        /> -->
        <!-- <p class="mt-2 text-sm text-gray-500">
         {{ $t("callqueueTable.destinationDescription") }}
        </p> -->
       </div>
       <div v-if="!this.openBankHolidaysForm" class="col-span-6">
        <label
         for="street-address"
         class="capitalize block text-sm font-normal text-gray-700">
         Destination
        </label>
        <vue-select
         v-if="
          !this.form.destination_type ||
          this.form.destination_type === 'extension' ||
          this.form.destination_type === 'callQueue' ||
          this.form.destination_type === 'voicemail'
         "
         v-model="searchDestNumber"
         :options="selectOptions"
         label-by="label"
         :close-on-select="true"
         :disabled="selectOptions.length <= 0"
         searchable
         clear-on-select
         :multiple="false"
         :placeholder="$t('search')"
         class="mt-1 focus:encom_border_input block w-full py-1 shadow-sm sm:text-sm border-gray-300 rounded">
        </vue-select>
        <div v-else-if="this.form.destination_type === 'externalNumber'">
         <input
          v-model="searchDestNumber"
          type="text"
          name="default_destination"
          id="default_destination"
          :class="
           ({ 'is-invalid': phoneError },
           'block w-full rounded-md border-0 py-1.5 text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-2 focus:ring-inset sm:text-sm sm:leading-6')
          " />
         <span v-if="phoneError" class="error">{{ phoneError }}</span>
        </div>
        <div v-else-if="this.form.destination_type === 'endCall'">
         <input
          v-model="searchDestNumber"
          type="text"
          name="default_destination"
          id="default_destination"
          disabled=""
          class="mt-1 focus:encom_border_input block w-full shadow-sm sm:text-sm border-gray-300 rounded bg-gray-200 cursor-not-allowed" />
        </div>
        <!-- <input
         v-model="this.form.destination"
         type="tel"
         name="destination"
         id="destination"
         autocomplete="destination"
         class="mt-1 focus:encom_border_input block w-full shadow-sm sm:text-sm border-gray-300 rounded" /> -->
        <p class="mt-2 text-sm text-gray-500">
         {{ $t("callqueueTable.destinationDescription") }}
        </p>
       </div>
       <div v-if="this.openBankHolidaysForm" class="col-span-6">
        <div>
         <h2 class="text-gray-500 text-xs font-medium uppercase tracking-wide">
          {{ $t("callqueueTable.nextFrenchHolidays") }}
         </h2>
         <ul
          role="list"
          class="mt-3 grid grid-cols-1 gap-5 sm:gap-6 sm:grid-cols-2 lg:grid-cols-4">
          <li
           v-for="(bankHolyday, title) in this.frenchHolidaysOver12Months"
           :key="bankHolyday.name"
           class="col-span-1 flex shadow-sm rounded">
           <div
            class="flex-1 flex items-center justify-between border border-gray-200 bg-white rounded-md truncate">
            <div class="flex-1 px-4 py-2 text-sm truncate">
             <p class="text-gray-900 font-medium hover:text-gray-600">
              {{ title }}
             </p>
             <p class="text-gray-500 capitalize">
              {{ $d(bankHolyday, "shortText") }}
             </p>
            </div>
           </div>
          </li>
         </ul>
        </div>
        <div class="mt-5 bg-yellow-50 border-l-4 border-yellow-400 p-4">
         <div class="flex">
          <div class="flex-shrink-0">
           <ExclamationIcon
            class="h-5 w-5 text-yellow-400"
            aria-hidden="true" />
          </div>
          <div class="ml-3">
           <p class="text-sm text-yellow-700">
            {{ $t("callqueueTable.addFrenchHolidaysWarning") }}.
            <b>{{ $t("callqueueTable.continue") }}</b>
           </p>
          </div>
         </div>
        </div>
       </div>
      </div>

      <div class="flex justify-end mt-5">
       <button
        @click="closeForm()"
        type="button"
        class="rounded bg-white py-2 px-4 border border-gray-300 text-sm font-medium text-gray-700 hover:bg-gray-50">
        {{ $t("ticket.cancel") }}
       </button>
       <button
        type="submit"
        class="capitalize rounded ml-3 disabled inline-flex justify-center py-2 px-4 border border-transparent shadow-sm text-sm font-medium body__table--header text-white">
        {{ this.openBankHolidaysForm ? $t("dialog.confirm") : $t("validate") }}
       </button>
      </div>
     </form>
    </div>
   </div>
  </div>
 </div>
 <ask-confirmation-dialog ref="askConfirmationDialog"></ask-confirmation-dialog>
 <ComfirmationDialog
  v-if="this.showMessage"
  :messageToShow="this.messageToShow"
  :subMessageToShow="this.subMessageToShow" />
</template>

<script>
import axios from "axios";

import VueSelect from "vue-next-select";
import "vue-next-select/dist/index.css";

import {
 ExclamationIcon,
 ExclamationCircleIcon,
 CheckCircleIcon,
 SelectorIcon,
 XCircleIcon,
 XIcon,
} from "@heroicons/vue/solid";
import { Menu, MenuButton, MenuItem, MenuItems } from "@headlessui/vue";
import AskConfirmationDialog from "../components/AskConfirmationDialog.vue";
import ComfirmationDialog from "../components/ConfirmationDialog.vue";
const account = localStorage.getItem("account");

const weekDays = [
 { id: 1, day: "weekdays.mon" },
 { id: 2, day: "weekdays.tue" },
 { id: 3, day: "weekdays.wed" },
 { id: 4, day: "weekdays.thu" },
 { id: 5, day: "weekdays.fri" },
 { id: 6, day: "weekdays.sat" },
 { id: 7, day: "weekdays.sun" },
];

const dest_type = [
 { name: "extension", value: "extension" },
 { name: "voicemail", value: "voicemail" },
 //  { name: "svi", value: "svi" },
 { name: "queue", value: "callQueue" },
 //  { name: "call flow", value: "callFlow" },
 { name: "end call", value: "endCall" },
 { name: "external number", value: "externalNumber" },
];
export default {
 name: "CreateNewCallQueueTimeSlot",
 props: [
  "openTimeSlotForm",
  "openExceptionForm",
  "openBankHolidaysForm",
  "call_queue_detail",
  "call_queue_id",
  "call_queue_group_id",
  "call_queue_group",
  "call_queue_exceptions",
  "call_queue_time_spans",
  "call_queue_group_time_spans",
  "call_queue_group_exceptions",
  "formatDateFrench",
  "fullFormatDateFrench",
 ],
 components: {
  CheckCircleIcon,
  ExclamationIcon,
  ExclamationCircleIcon,
  SelectorIcon,
  XCircleIcon,
  XIcon,
  ComfirmationDialog,
  AskConfirmationDialog,
  Menu,
  MenuButton,
  MenuItem,
  MenuItems,
  VueSelect,
 },
 setup() {
  return {
   weekDays,
  };
 },
 data() {
  return {
   dayClicked: false,
   destinationType: "",
   selectOptions: [],
   selectOptionsNumbers: [],
   phoneError: null,
   searchDestNumber: "",
   destNumber: null,
   searchNumber: "",
   dest_type,
   account,
   form: {
    destination_type: null,
    day: "",
    dayOfWeek: [],
    fullDates: [],
    start: "",
    end: "",
    destination: null,
    reference: null,
   },
   weekDay: true,
   specificDay: false,
   days: [],
   pickedStartBigger: false,
   checked: false,
   showMessage: false,
   errorMessage: false,
   closeNotification: true,
   withTransition: true,
   messageToShow: "",
   subMessageToShow: "",
   frenchHolidaysOver12Months: {},
   frenchHolidaysDateOver12Months: [],
   callQueuesOptions: null,
   extensionOptions: [],
   queueOptions: [],
   didOptions: [],
  };
 },
 computed: {
  computedTransition() {
   return this.withTransition && "fade";
  },
  dates() {
   return this.days.map(day => day.date);
  },
  attributes() {
   if (this.days.length <= 0) {
    return null;
   }
   return this.dates.map(date => ({
    highlight: true,
    dates: date,
   }));
  },
 },
 methods: {
  async getCallQueuesOptions() {
   const res = await axios.get(
    `${this.$cookie.getCookie(
     "API"
    )}/api/v1/callQueues/options?customerAccount=${this.account}`,
    {
     headers: {
      Authorization: `Bearer ${localStorage.getItem("token")}`,
     },
    }
   );

   try {
    this.callQueuesOptions = res.data;
    console.log("getCallQueuesOptions ~ res.data:", res.data);
    this.fillDest(res.data);
    // this.callQueuesDIDOptions = res.data?.dids;
    // this.callQueuesExtensionOptions = res.data?.extensions;
    // this.callQueuesQueueOptions = res.data?.queues;
   } catch (error) {
    this.errorHandling(error);
   }
  },
  fillDest(data) {
   Object.keys(data?.extensions).forEach(val => {
    let obj = {};
    obj["value"] = val;
    obj["label"] = data?.extensions[val];
    this.extensionOptions.push(obj);
   });
   Object.keys(data?.queues).forEach(val => {
    let objQueue = {};
    objQueue["value"] = val;
    objQueue["label"] = data?.queues[val];
    this.queueOptions.push(objQueue);
   });
  },
  fillOptions() {
   let queues = [];
   let dids = [];

   Object.keys(this.callQueuesOptions?.queues).forEach(val => {
    let obj = {};
    obj["value"] = val;
    obj["label"] = this.callQueuesOptions?.queues[val];
    queues.push(obj);
   });
   Object.keys(this.callQueuesOptions?.dids).forEach(val => {
    let obj = {};
    obj["value"] = val;
    obj["label"] = this.callQueuesOptions?.dids[val];
    dids.push(obj);
   });
   switch (this.type) {
    case "did":
     this.selectOptionsNumbers = dids;
     break;
    case "queue":
     this.selectOptionsNumbers = queues;
     break;

    default:
     console.log("Unknown action");
     break;
   }
  },
  async getFrenchHolidaysOver12Months() {
   const res = await axios.get(
    `${this.$cookie.getCookie(
     "API"
    )}/api/v1/helpers/time/frenchHolidaysOver12Months`,
    {
     headers: {
      "Content-Type": "application/json",
     },
    }
   );

   try {
    this.frenchHolidaysOver12Months = res.data.response;
    for (const key in this.frenchHolidaysOver12Months) {
     if (Object.hasOwnProperty.call(this.frenchHolidaysOver12Months, key)) {
      this.frenchHolidaysDateOver12Months.push(
       this.frenchHolidaysOver12Months[key]
      );
     }
    }
   } catch (error) {
    this.errorHandling(error);
    this.$emit("activeBanner");
    this.$emit("success", false);
    this.$emit("successMsg", this.errorDataResponse);
   }
  },
  async createTimeSlot(msg, title, confirmationMessage, confirmButton, goBack) {
   if (this.weekDay) {
    this.form.fullDates = [];
   }
   if (this.specificDay) {
    this.checked = false;
    this.form.dayOfWeek = [];
   }
   if (this.call_queue_group_time_spans) {
    if (this.checked) {
     //create a timeslot for a call queue group and for all the day
     const options = {
      method: "POST",
      url: `${this.$cookie.getCookie("API")}/api/v1/callQueueGroupTimeSpans`,
      params: {
       customerAccount: this.account,
       call_queue_group_id: this.call_queue_group_id,
      },
      headers: {
       "Content-Type": "application/json",
       Authorization: `Bearer ${localStorage.getItem("token")}`,
      },
      data: {
       daysOfWeek: [1, 2, 3, 4, 5, 6, 7],
       start: this.form.start,
       end: this.form.end,
       reference: this.form.reference,
       destination_type: this.form.destination_type,
       destination: this.form.destination,
      },
     };

     axios
      .request(options)
      .then(res => {
       this.$emit("activeBanner");
       this.$emit("success", true);
       this.$emit("successMsg", msg);
       this.cleanForm();
      })
      .catch(error => {
       this.errorHandling(error);
       this.$emit("activeBanner");
       this.$emit("success", false);
       this.$emit("successMsg", this.errorDataResponse);
      });
    } else {
     //create a timeslot for a call queue group and for days specified by the user
     const options = {
      method: "POST",
      url: `${this.$cookie.getCookie("API")}/api/v1/callQueueGroupTimeSpans`,
      params: {
       customerAccount: this.account,
       call_queue_group_id: this.call_queue_group_id,
      },
      headers: {
       "Content-Type": "application/json",
       Authorization: `Bearer ${localStorage.getItem("token")}`,
      },
      data: {
       daysOfWeek:
        this.form.fullDates && this.form.fullDates.length > 0
         ? []
         : this.form.dayOfWeek,
       fullDates:
        this.form.dayOfWeek && this.form.dayOfWeek.length > 0
         ? []
         : this.form.fullDates,
       start: this.form.start,
       end: this.form.end,
       reference: this.form.reference,
       destination_type: this.form.destination_type,
       destination: this.form.destination,
      },
     };

     axios
      .request(options)
      .then(res => {
       this.$emit("activeBanner");
       this.$emit("success", true);
       this.$emit("successMsg", msg);
       this.cleanForm();
      })
      .catch(error => {
       this.errorHandling(error);
       this.$emit("activeBanner");
       this.$emit("success", false);
       this.$emit("successMsg", this.errorDataResponse);
      });
    }
   } else {
    if (this.checked) {
     //create a timeslot for a call queue and for all the day
     const options = {
      method: "POST",
      url: `${this.$cookie.getCookie("API")}/api/v1/callQueueTimeSpans`,
      params: {
       customerAccount: this.account,
       call_queue_id: this.call_queue_id,
      },
      headers: {
       "Content-Type": "application/json",
       Authorization: `Bearer ${localStorage.getItem("token")}`,
      },
      data: {
       daysOfWeek: [1, 2, 3, 4, 5, 6, 7],
       start: this.form.start,
       end: this.form.end,
       reference: this.form.reference,
       destination: this.form.destination,
       destination_type: this.form.destination_type,
      },
     };
     //display warning window if this callqueue is link to a callqueue group
     if (this.call_queue_group) {
      const r = await this.$refs.askConfirmationDialog.show({
       title: title,
       message: confirmationMessage,
       okButton: confirmButton,
       cancelButton: goBack,
       action: "delete",
      });
      if (r) {
       this.unlinkCallQueue();
       axios
        .request(options)
        .then(res => {
         this.$emit("activeBanner");
         this.$emit("success", true);
         this.$emit("successMsg", msg);
         this.cleanForm();
        })
        .catch(error => {
         this.errorHandling(error);
         this.$emit("activeBanner");
         this.$emit("success", false);
         this.$emit("successMsg", this.errorDataResponse);
        });
      }
     } else {
      axios
       .request(options)
       .then(res => {
        this.$emit("activeBanner");
        this.$emit("success", true);
        this.$emit("successMsg", msg);
        this.cleanForm();
       })
       .catch(error => {
        this.errorHandling(error);
        this.$emit("activeBanner");
        this.$emit("success", false);
        this.$emit("successMsg", this.errorDataResponse);
       });
     }
    } else {
     //  return console.log(this.form);
     //create a timeslot for a call queue and for days specified by the user
     const options = {
      method: "POST",
      url: `${this.$cookie.getCookie("API")}/api/v1/callQueueTimeSpans`,
      params: {
       customerAccount: this.account,
       call_queue_id: this.call_queue_id,
      },
      headers: {
       "Content-Type": "application/json",
       Authorization: `Bearer ${localStorage.getItem("token")}`,
      },
      data: {
       daysOfWeek:
        this.form.fullDates && this.form.fullDates.length > 0
         ? []
         : this.form.dayOfWeek,
       fullDates:
        this.form.dayOfWeek && this.form.dayOfWeek.length > 0
         ? []
         : this.form.fullDates,
       start: this.form.start,
       end: this.form.end,
       reference: this.form.reference,
       destination_type: this.form.destination_type,
       destination: this.form.destination,
      },
     };
     //display warning window if this callqueue is link to a callqueue group
     if (this.call_queue_group) {
      const r = await this.$refs.askConfirmationDialog.show({
       title: title,
       message: confirmationMessage,
       okButton: confirmButton,
       cancelButton: goBack,
       action: "delete",
      });
      if (r) {
       this.unlinkCallQueue();
       axios
        .request(options)
        .then(res => {
         this.$emit("activeBanner");
         this.$emit("success", true);
         this.$emit("successMsg", msg);
         this.cleanForm();
        })
        .catch(error => {
         this.errorHandling(error);
         this.$emit("activeBanner");
         this.$emit("success", false);
         this.$emit("successMsg", this.errorDataResponse);
        });
      }
     } else {
      axios
       .request(options)
       .then(res => {
        this.$emit("activeBanner");
        this.$emit("success", true);
        this.$emit("successMsg", msg);
        this.cleanForm();
       })
       .catch(error => {
        this.errorHandling(error);
        this.$emit("activeBanner");
        this.$emit("success", false);
        this.$emit("successMsg", this.errorDataResponse);
       });
     }
    }
   }
  },
  async createException(
   msg,
   title,
   confirmationMessage,
   confirmButton,
   goBack
  ) {
   if (this.call_queue_group_exceptions) {
    //create an exception for a call queue group
    const options = {
     method: "POST",
     url: `${this.$cookie.getCookie("API")}/api/v1/callQueueGroupExceptions`,
     params: {
      customerAccount: this.account,
      call_queue_group_id: this.call_queue_group_id,
     },
     headers: {
      "Content-Type": "application/json",
      Authorization: `Bearer ${localStorage.getItem("token")}`,
     },
     data: {
      day: this.form.day,
      reference: this.form.reference,
      destination: this.form.destination,
      destination_type: this.form.destination_type,
     },
    };
    if (this.form.day && !this.checkmakingOfDate()) {
     axios
      .request(options)
      .catch(err => {
       if (err.response) {
        if (err.response.status == 500) {
         window.alert(
          "Oups, something went wrong...\nReason: " + err.response.statusText
         );
        } else if (err.response.status == 404) {
         window.alert("Document " + err.response.statusText);
        }
       }
      })
      .then(res => {
       this.$emit("activeBanner");
       this.$emit("success", true);
       this.$emit("successMsg", msg);
       this.cleanForm();
      });
    } else if (!this.form.day) {
     this.errorMessage = true;
    } else {
    }
   } else {
    //create an exception for one call queue
    const options = {
     method: "POST",
     url: `${this.$cookie.getCookie("API")}/api/v1/callQueueExceptions`,
     params: {
      customerAccount: this.account,
      call_queue_id: this.call_queue_id,
     },
     headers: {
      "Content-Type": "application/json",
      Authorization: `Bearer ${localStorage.getItem("token")}`,
     },
     data: {
      day: this.form.day,
      destination: this.form.destination,
      reference: this.form.reference,
      // destination: this.form.destination,
      destination_type: this.form.destination_type,
     },
    };
    //display warning window if this callqueue is link to a callqueue group
    if (this.call_queue_group) {
     const r = await this.$refs.askConfirmationDialog.show({
      title: title,
      message: confirmationMessage,
      okButton: confirmButton,
      cancelButton: goBack,
      action: "delete",
     });
     if (r) {
      this.unlinkCallQueue();
      if (this.form.day && !this.checkmakingOfDate()) {
       axios
        .request(options)
        .catch(err => {
         if (err.response) {
          if (err.response.status == 500) {
           window.alert(
            "Oups, something went wrong...\nReason: " + err.response.statusText
           );
          } else if (err.response.status == 404) {
           window.alert("Document " + err.response.statusText);
          }
         }
        })
        .then(res => {
         this.$emit("activeBanner");
         this.$emit("success", true);
         this.$emit("successMsg", msg);
         this.cleanForm();
        });
      } else if (!this.form.day) {
       this.errorMessage = true;
      } else {
      }
     }
    } else {
     if (this.form.day && !this.checkmakingOfDate()) {
      axios
       .request(options)
       .catch(err => {
        if (err.response) {
         if (err.response.status == 500) {
          window.alert(
           "Oups, something went wrong...\nReason: " + err.response.statusText
          );
         } else if (err.response.status == 404) {
          window.alert("Document " + err.response.statusText);
         }
        }
       })
       .then(res => {
        this.$emit("activeBanner");
        this.$emit("success", true);
        this.$emit("successMsg", msg);
        this.cleanForm();
       });
     } else if (!this.form.day) {
      this.errorMessage = true;
     } else {
     }
    }
   }
  },
  async createExceptionForBankHolidays(
   msg,
   title,
   confirmationMessage,
   confirmButton,
   goBack
  ) {
   //create 12 exception (12 following french bank holidays) for a call queue group
   if (this.call_queue_group_exceptions) {
    const options = {
     method: "POST",
     url: `${this.$cookie.getCookie(
      "API"
     )}/api/v1/callQueueGroupExceptions/holidays`,
     params: {
      customerAccount: this.account,
      call_queue_group_id: this.call_queue_group_id,
     },
     headers: {
      "Content-Type": "application/json",
      Authorization: `Bearer ${localStorage.getItem("token")}`,
     },
    };
    axios
     .request(options)
     .then(res => {
      this.$emit("activeBanner");
      this.$emit("success", true);
      this.$emit("successMsg", msg);
     })
     .catch(err => {
      this.$emit("activeBanner");
      this.$emit("success", false);
      this.$emit("successMsg", err.response.statusText);
     });
   } else {
    const options = {
     method: "POST",
     url: `${this.$cookie.getCookie(
      "API"
     )}/api/v1/callQueueExceptions/holidays`,
     params: {
      customerAccount: this.account,
      call_queue_id: this.call_queue_id,
     },
     headers: {
      "Content-Type": "application/json",
      Authorization: `Bearer ${localStorage.getItem("token")}`,
     },
    };

    //display warning window if this callqueue is link to a callqueue group
    if (this.call_queue_group) {
     const r = await this.$refs.askConfirmationDialog.show({
      title: title,
      message: confirmationMessage,
      okButton: confirmButton,
      cancelButton: goBack,
      action: "delete",
     });
     if (r) {
      this.unlinkCallQueue();
      axios
       .request(options)
       .catch(err => {
        if (err.response) {
         if (err.response.status == 500) {
          window.alert(
           "Oups, something went wrong...\nReason: " + err.response.statusText
          );
         } else if (err.response.status == 404) {
          window.alert("Document " + err.response.statusText);
         }
        }
       })
       .then(res => {
        this.$emit("activeBanner");
        this.$emit("success", true);
        this.$emit("successMsg", msg);
       });
     }
    } else {
     axios
      .request(options)
      .catch(err => {
       if (err.response) {
        if (err.response.status == 500) {
         window.alert(
          "Oups, something went wrong...\nReason: " + err.response.statusText
         );
        } else if (err.response.status == 404) {
         window.alert("Document " + err.response.statusText);
        }
       }
      })
      .then(res => {
       this.$emit("activeBanner");
       this.$emit("success", true);
       this.$emit("successMsg", msg);
      });
    }
   }
  },
  unlinkCallQueue() {
   const options = {
    method: "PUT",
    url: `${this.$cookie.getCookie("API")}/api/v1/callQueues/unlink`,
    params: {
     customerAccount: this.account,
     callQueueId: this.call_queue_detail.id,
    },
    headers: {
     "Content-Type": "application/json",
     Authorization: `Bearer ${localStorage.getItem("token")}`,
    },
   };
   axios.request(options).catch(error => {
    this.errorHandling(error);
    this.$emit("activeBanner");
    this.$emit("success", false);
    this.$emit("successMsg", this.errorDataResponse);
   });
  },
  checkmakingOfDate() {
   if (this.call_queue_group_exceptions) {
    let pickedDay =
     JSON.stringify(this.form.day).slice(1, 11) + "T00:00:00.000000Z";
    let day = this.call_queue_group_exceptions.find(
     day => day.day === pickedDay
    );
    if (day) {
     return true;
    } else {
     return false;
    }
   } else {
    let pickedDay =
     JSON.stringify(this.form.day).slice(1, 11) + "T00:00:00.000000Z";
    let day = this.call_queue_exceptions.find(day => day.day === pickedDay);
    if (day) {
     return true;
    } else {
     return false;
    }
   }
  },
  checkmakingOfBankHolidays() {
   this.form.reference = null;
   let pickedDay =
    JSON.stringify(this.form.day).slice(1, 11) + "T00:00:00.000000Z";
   let bankHolyday = this.frenchHolidaysDateOver12Months.find(
    bankHolyday =>
     this.formatDateFrench(bankHolyday) === this.formatDateFrench(pickedDay)
   );
   for (const [frenchHolidays, date] of Object.entries(
    this.frenchHolidaysOver12Months
   )) {
    if (date === bankHolyday) {
     this.form.reference = frenchHolidays;
    }
   }
  },
  checkmakingTimeSlot() {
   let pickedStart = this.form.start;
   let pickedStartSplit = pickedStart.split(":");
   let pickedStartInt = parseInt(pickedStartSplit[0] + pickedStartSplit[1]);
   let pickedEnd = this.form.end;
   let pickedEndSplit = pickedEnd.split(":");
   let pickedEndInt = parseInt(pickedEndSplit[0] + pickedEndSplit[1]);
   let checkDay = [];
   let checkStart;
   let checkEnd;
   let checkBetween;
   this.pickedStartBigger = false;

   //Check if i'm inside a callqueue group detail
   if (this.call_queue_group_time_spans) {
    // check for timeslot for specific day
    if (this.specificDay) {
     let pickedDay = this.form.fullDates;
     for (const value of pickedDay) {
      const dateCreated = new Date(value);
      const formatedDate =
       dateCreated.getFullYear() +
       "-" +
       ("0" + dateCreated.getDate()).slice(-2) +
       "-" +
       ("0" + (dateCreated.getMonth() + 1)).slice(-2);
      const res = this.call_queue_group_time_spans.filter(
       dayOfWeek => dayOfWeek.fullDate === formatedDate
      );
      if (res.length > 0 && pickedStartInt < pickedEndInt) {
       for (let index = 0; index < res.length; index++) {
        const timeSpans = res[index];
        checkDay.push(timeSpans);
        checkStart = checkDay.find(
         checkStart =>
          //Issue on only picked start
          //picked start bigger than existed start and smaller than existed end
          pickedStart >= checkStart.start.slice(0, 5) &&
          pickedStart <= checkStart.end.slice(0, 5)
        );
        checkEnd = checkDay.find(
         checkEnd =>
          //Issue on only picked end
          //picked end bigger than existed start and smaller than existed end
          pickedEnd >= checkEnd.start.slice(0, 5) &&
          pickedEnd <= checkEnd.end.slice(0, 5)
        );
        checkBetween = checkDay.find(
         checkBetween =>
          //Issue on picked start and picked end
          //picked start smaller than existed start and picked end smaller than existed end
          checkBetween.end.slice(0, 5) <= pickedEnd &&
          pickedStart <= checkBetween.start.slice(0, 5)
        );
       }
      } else if (pickedStartInt >= pickedEndInt) {
       //picked start bigger than picked end
       this.pickedStartBigger = true;
      }
     }
    } else {
     let pickedDay = this.form.dayOfWeek;
     for (const value of pickedDay) {
      const res = this.call_queue_group_time_spans.filter(
       dayOfWeek => dayOfWeek.dayOfWeek === value
      );
      if (res.length > 0 && pickedStartInt < pickedEndInt) {
       for (let index = 0; index < res.length; index++) {
        const timeSpans = res[index];
        checkDay.push(timeSpans);
        checkStart = checkDay.find(
         checkStart =>
          //Issue on only picked start
          //picked start bigger than existed start and smaller than existed end
          pickedStart >= checkStart.start.slice(0, 5) &&
          pickedStart <= checkStart.end.slice(0, 5)
        );
        checkEnd = checkDay.find(
         checkEnd =>
          //Issue on only picked end
          //picked end bigger than existed start and smaller than existed end
          pickedEnd >= checkEnd.start.slice(0, 5) &&
          pickedEnd <= checkEnd.end.slice(0, 5)
        );
        checkBetween = checkDay.find(
         checkBetween =>
          //Issue on picked start and picked end
          //picked start smaller than existed start and picked end smaller than existed end
          checkBetween.end.slice(0, 5) <= pickedEnd &&
          pickedStart <= checkBetween.start.slice(0, 5)
        );
       }
      } else if (pickedStartInt >= pickedEndInt) {
       //picked start bigger than picked end
       this.pickedStartBigger = true;
      }
     }
    }
   } else {
    //inside a callqueue detail
    if (this.specificDay) {
     //check for timeslot for specific day
     let pickedDay = this.form.fullDates;

     for (const value of pickedDay) {
      const dateCreated = new Date(value);
      const formatedDate =
       dateCreated.getFullYear() +
       "-" +
       ("0" + dateCreated.getDate()).slice(-2) +
       "-" +
       ("0" + (dateCreated.getMonth() + 1)).slice(-2);
      const res = this.call_queue_time_spans.filter(
       dayOfWeek => dayOfWeek.fullDate === formatedDate
      );
      if (res.length > 0 && pickedStartInt < pickedEndInt) {
       for (let index = 0; index < res.length; index++) {
        const timeSpans = res[index];
        checkDay.push(timeSpans);
        checkStart = checkDay.find(
         checkStart =>
          //Issue on only picked start
          //picked start bigger than existed start and smaller than existed end
          pickedStart >= checkStart.start.slice(0, 5) &&
          pickedStart <= checkStart.end.slice(0, 5)
        );
        checkEnd = checkDay.find(
         checkEnd =>
          //Issue on only picked end
          //picked end bigger than existed start and smaller than existed end
          pickedEnd >= checkEnd.start.slice(0, 5) &&
          pickedEnd <= checkEnd.end.slice(0, 5)
        );
        checkBetween = checkDay.find(
         checkBetween =>
          //Issue on picked start and picked end
          //picked start smaller than existed start and picked end smaller than existed end
          checkBetween.end.slice(0, 5) <= pickedEnd &&
          pickedStart <= checkBetween.start.slice(0, 5)
        );
       }
      } else if (pickedStartInt >= pickedEndInt) {
       this.pickedStartBigger = true;
      }
     }
    } else {
     let pickedDay = this.form.dayOfWeek;
     for (const value of pickedDay) {
      const res = this.call_queue_time_spans.filter(
       dayOfWeek => dayOfWeek.dayOfWeek === value
      );
      if (res.length > 0 && pickedStartInt < pickedEndInt) {
       for (let index = 0; index < res.length; index++) {
        const timeSpans = res[index];
        checkDay.push(timeSpans);
        checkStart = checkDay.find(
         checkStart =>
          //Issue on only picked start
          //picked start bigger than existed start and smaller than existed end
          pickedStart >= checkStart.start.slice(0, 5) &&
          pickedStart <= checkStart.end.slice(0, 5)
        );
        checkEnd = checkDay.find(
         checkEnd =>
          //Issue on only picked end
          //picked end bigger than existed start and smaller than existed end
          pickedEnd >= checkEnd.start.slice(0, 5) &&
          pickedEnd <= checkEnd.end.slice(0, 5)
        );
        checkBetween = checkDay.find(
         checkBetween =>
          //Issue on picked start and picked end
          //picked start smaller than existed start and picked end smaller than existed end
          checkBetween.end.slice(0, 5) <= pickedEnd &&
          pickedStart <= checkBetween.start.slice(0, 5)
        );
       }
      } else if (pickedStartInt >= pickedEndInt) {
       this.pickedStartBigger = true;
      }
     }
    }
   }

   return { checkStart, checkEnd, checkBetween };
  },
  sendMessage(msg, subMsg) {
   this.showMessage = true;
   this.messageToShow = msg;
   this.subMessageToShow = subMsg;
  },
  sendErrorMessage() {
   this.errorMessage = true;
   this.messageToShow = "Sorry, something went wrong";
   this.subMessageToShow = "Select an exception date in the calendar";
  },
  closeForm() {
   this.cleanForm();
   this.$emit("closeForm");
  },
  cleanForm() {
   this.checked = false;
   this.form.dayOfWeek = [];
   this.form.fullDates = [];
   this.form.day = "";
   this.form.start = "";
   this.form.end = "";
   this.form.destination = null;
   this.form.reference = "";
   this.form.destination_type = null;
   this.days = [];
   this.attributes = [];
  },
  switchTabs() {
   if (!this.weekDay) {
    this.weekDay = !this.weekDay;
    this.specificDay = false;
    this.form.dayOfWeek = [];
    this.form.day = "";
    this.form.start = "";
    this.form.end = "";
    this.form.destination = "";
    this.form.reference = "";
   }
  },
  switchTabs2() {
   if (!this.specificDay) {
    this.specificDay = !this.specificDay;
    this.weekDay = false;
    this.form.fullDates = [];
    this.days = [];
    this.attributes = [];
    this.form.day = "";
    this.form.start = "";
    this.form.end = "";
    this.form.destination = "";
    this.form.reference = "";
   }
  },
  onDayClick(day) {
   this.dayClicked = !this.dayClicked;
   const idx = this.form.fullDates.findIndex(
    d => d === this.formatDateFrench(day.date)
   );
   const datesIdx = this.days.findIndex(d => d.id === day.id);
   if (datesIdx >= 0) {
    this.days.splice(datesIdx, 1);
   } else {
    this.days.push({
     id: day.id,
     date: day.date,
    });
   }
   if (idx >= 0) {
    this.form.fullDates.splice(idx, 1);
   } else {
    this.form.fullDates.push(this.formatDateFrench(day.date));
   }
  },
 },
 watch: {
  searchDestNumber: function (val) {
   if (this.form.destination_type === "externalNumber") {
    this.validatePhoneNumber();
    this.destNumber = val;
    this.form.destination = val;
   } else {
    this.destNumber = val?.value;
    this.form.destination = val?.value;
   }
  },
  destinationType: function (val) {
   this.form.destination_type = val;
   switch (val) {
    case "extension":
     this.selectOptions = this.extensionOptions;
     break;
    case "voicemail":
     this.selectOptions = this.extensionOptions;
     break;
    case "svi":
     // this.selectOptions = extensions;
     break;
    case "callQueue":
     this.selectOptions = this.queueOptions;
     break;
    case "callFlow":
     // this.selectOptions = extensions;
     break;
    case "endCall":
     // this.selectOptions = extensions;
     break;
    case "externalNumber":
     this.searchDestNumber = "";
     // this.selectOptions = extensions;
     break;
    default:
     console.log("Unknown action");
     break;
   }
  },
 },
 mounted() {
  this.getFrenchHolidaysOver12Months();
  this.getCallQueuesOptions();
 },
};
</script>

<style scoped>
.fade-leave-active {
 transition: opacity 0.5s;
}
.fade-leave-to /* .fade-leave-active below version 2.1.8 */ {
 opacity: 0;
}
.is-invalid {
 border: 1px solid red !important;
 /* border-color: red !important; */
}
.error {
 color: red;
 font-size: 0.875em;
}
</style>
